<template>
  <div>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="116 -129.5 256.2 283.5"
      style="enable-background:new 116 -129.5 256.2 283.5;"
      xml:space="preserve"
    >
      <g>
        <g>
          <g>
            <path
              class="st0"
              d="M218.5,146.8c2.2,0,6-0.7,9-5.5c-1.7-3.8-2.9-11.4-3.1-14.2c-0.9-14.3,4.6-27.7,10.6-28
				c4.4-0.3,7.9,3.9,8.3,10.7c1.1,17.8-4.8,25.2-7.8,31.6c0.5,2.5,4.7,6.7,8.7,6.7c1.5,0,3.2-0.7,3.7-1.3c-0.3-1-0.6-2.3-0.6-4.3
				c0-10.3,6.6-19,12.4-19c4.6,0,7.2,6,7.2,10c0,4.7-4,8.5-9.8,12.2c1,1,2.9,1.8,4.6,1.8c8.4,0,12.8-10.1,16-19.4
				c0.6-1.8,1.6-4.3,3.7-4.3c3.5,0,5,23.2,10.9,23.2c2.5,0,4.6-5.1,4.6-9.1c0-2.9-1-3.2-1-7.5c0-4.7,2.5-8.7,5.9-8.7
				c2.1,0,2.8,2,2.8,4.5c0,9.4-6.8,26.9-16.8,26.9c-7.5,0-6.9-12.9-9.6-12.9c-3,0-7.1,13.1-21.6,13.1c-2.4,0-4.9-1.2-6.9-3.5
				c-3.5,2.4-6.5,3.4-9.9,3.4c-5.4,0-7.8-5.6-10-5.6c-2.6,0-5.6,5.7-14.4,5.7c-7.1,0-8.2-9.4-10.7-9.4c-2.2,0-5.6,9.4-9.3,9.4
				c-6.2,0-7.5-16.3-12.9-16.3c-4.3,0-17.7,16.6-21.4,16.2c-1.5-0.2-2.1-1.3-1.9-2.7c0.2-2,2-7.5,2.7-10.1c1.2-4.5,2.3-9.4,2.9-10.9
				c0.6-1.6,2.2-2.7,3.5-2.6c0.7,0.1,0.9,1.3,1.1,2.3c0.7,3.6,1.8,7.3,2.9,7.3c2.1,0,8.9-13.1,12.9-13.1c4.9,0,5.4,20.6,12.1,20.6
				c2.2,0,3.8-2.6,5.3-5.9c0.1-5.4,1.6-13.2,6-13.2c3.1,0,4.6,6.9,2.3,12.9C210.7,145,215.4,146.8,218.5,146.8z M336.1,129.1
				c-1.8,7.6-7.3,18.7-15.6,18.7c-1.9,0-5.3-0.7-5.3-2.7c0-2.1,12.9-2.3,12.9-12.2c0-3.7-3.4-8.8-8.4-8.8c-6.6,0-13.1,7.2-13.1,17.2
				c0,9,5.6,11.9,11.1,11.9c9.7,0,15.6-7.9,18.7-14.6c1.5,4.4,2.3,14.6,6,14.6c1.5,0,2.5-0.7,4.3-4.7c4.4-9.9,8.7-21.2,14.6-21.2
				c2.2,0,0.9,4,6.2,4c2.5,0,4.3-2.1,4.3-4.4c0-3.4-3.1-6-7.8-6c-13.8,0-16.8,20.7-19,20.7c-3.7,0-2.5-16.6-5.1-16.6
				C337.5,125,336.4,127.8,336.1,129.1z M238.5,113.7c-0.3-4.1-1.2-6-3.5-5.8c-2.9,0.2-4.3,9.4-3.7,19.1c0.4,6.3,1.3,7.3,2.2,7.2
				C235.2,134.1,239.1,122.8,238.5,113.7z M214.6,105c-0.2-2.4-2.1-4-4.6-4c-2.8,0-5.4,2.9-5.1,6.6c0.2,2.4,1.6,4.4,4.4,4.4
				C212.6,112.1,214.9,108.4,214.6,105z M260.4,135c0-2.5-1.3-4.6-2.9-4.6c-1.3,0-4.4,1.5-4.4,6.2c0,3.7,1.3,5.4,1.9,6.2
				C259.2,141.5,260.4,138,260.4,135z M317.8,130c3.1,0,4,3.7,3.2,5.9c-0.7,2.2-3.4,4.7-7.5,5.4C312.7,139.1,312.2,130,317.8,130z M148.5,119.6c-0.4-1.3-1.6-4.3-2-6.6c-0.7-4.9,1.8-8.1,4.2-8.9c2.6-0.7,3.7,0.6,4.7,3.7c1.3,4.7,1.5,10.9,1,19.7
				c-0.6,12.1-6.6,26.6-21.6,26.3c-12.9-0.3-18.7-8.7-19.3-21.5c-0.3-6,0.1-12.1,2.6-20.6c1.3-4.6,2.5-6.2,6.6-7.1
				c3.2-0.6,2.9,2.8,2.2,6.8c-1.6,8.8-2.4,13.1-1.8,23.1c0.6,10.6,5.4,14.7,10.7,14.7c7.9,0.2,11.5-6.5,12.9-16
				C150.2,125.6,149.3,121.9,148.5,119.6z"
            />
          </g>
        </g>
        <g>
          <path
            class="st0"
            d="M321.9-71.7c1,7.4,12.2,17.4,15.3,17.2c2-0.2,2.8-9.7,2.8-17.2c0-4.3,0.2-7.6-2-8.4
			C334.4-72.8,325-71.4,321.9-71.7z M322.6-74.9c8.1,0,15.5-6.5,15.5-12.2c0-2.7-1.3-4.8-4.5-5.2c0.5,0.7,0.8,1.2,0.8,2.5
			c0,4.3-6.5,9.1-13.6,9.1c-2.6,0-3.4,1.8-3.4,2.7C317.4-75.9,319.5-74.9,322.6-74.9z M320.9-83.8c-1.1,0-2.7,0-3.5-1.7
			c-0.7-1.4-0.5-3.7-1.8-4.7c1.3,0.4,2.7,0.4,3.8,0.4c3.5,0,6.9-2.4,9.5-2.4c1.7,0,2.2,1.1,2.2,2.2
			C331.1-86.6,324.1-83.8,320.9-83.8z"
          />
          <path
            class="st0"
            d="M146.4-22.6c2.6,0,2.1-16.2,6.4-16.2c1,0,1.3,1,1.3,2.3c0,3.7-1.8,9.1-1.8,12c0,1.5,0.4,2,1.1,2
			c1.9,0,5.1-17.9,8.3-17.9c1,0,1.5,1,1.5,2c0,3.7-4.8,14.5-4.8,17.5c0,0.5,0.2,0.9,0.5,0.9c2,0,5.9-8.4,7.1-12.6
			c0.7-2.2,1.6-3.4,2.5-3.4c0.8,0,1.5,0.9,1.5,2.7c0,4.2-3.9,14.1-7.3,20.8C160.1-7.5,156-0.1,156,4.1c0,2.1,0.4,4.3,3.4,6.9
			c-5-0.3-7-0.8-8.9-4.7c-8.3,16.6,15.4,15,7.3,29.7c-1.5-2.6-13.2-5.4-13.2-20.8c0-13.3,11.7-21.1,11.7-26.7c0-1.1-0.4-2-1.4-2.9
			c-2.6-2.2-2.7-4-4.7-4c-1.5,0-3.3,3.4-1.4,6.5c0.6,1,1.2,1.6,1.2,2.6c0,2.2-3.4,8-5.8,8c-2.4,0-4.3-6-4.3-15.7
			c0-8.6,1.9-17.2,4.4-17.2c0.7,0,0.9,0.5,0.9,1.1c0,1-0.3,3-0.3,6.5C144.8-22.9,145.6-22.6,146.4-22.6z"
          />
          <path
            class="st0"
            d="M166.2,0.3c3.3,0,6-2.4,6-7.8v-8c0-3,0.2-6.5,2.3-8.9c-0.2,1-0.2,2.1-0.2,3.1c0,6.7,3.7,11.3,3.7,17.8
			c0,2.4-0.7,3.7-2.6,3.7c-1.8,0-1.6-1.6-2-1.6c-0.3,0-0.8,1-0.8,2.1c0,1.6,1.2,4.2,4.4,4.2c2.8,0,5.1-2.6,5.1-5.5
			c0-7-5.5-14.8-5.5-22.3c0-3.3,0.9-5.5,1.6-7.2c0,15.5,13.2,26.8,13.2,33.3c0,1.5-1.1,2.7-2.7,2.7c-1.8,0-2.7-1.4-2.7-2.5
			c0-0.5-0.1-0.7-0.3-0.7c-0.5,0-1.9,1.6-1.9,3.6c0,2.6,2.1,5.7,6,5.7c4.4,0,6.9-3.7,6.9-7.1c0-8.2-16-20.6-16-33.9
			c0-2.3,0.4-5.9,2-8.7c-10.3,6.6-13.5,15.4-13.5,23.2c0,6.7-0.2,10.1-3.7,10.1c-1.8,0-2.3-1.6-2.9-1.6c-0.5,0-0.7,1.4-0.7,1.8
			C162-1.4,164.1,0.3,166.2,0.3z"
          />
          <path
            class="st0"
            d="M301.4-77.7c3.4,0,6-1.6,6-2.5c0-0.3-0.5-0.5-1.5-0.5c-1,0-3-0.1-6.9-0.7c-3.3-0.5-5.8-1.8-6.7-1.8
			c-0.3,0-0.5,0.2-0.5,0.4C291.9-80.8,295.7-77.7,301.4-77.7z M284.4-87.6c1-0.7,5.4,0.4,11.1-2.4c0.7-0.4,1.4-0.5,2-0.5
			c2.7,0,2,3.1,4.5,3.1c1.9,0,4.4-1.6,5.9-1.6c2.3,0,5.7,7.3,5.7,10.8c0,4-6,6.4-13.1,6.4c-10.4,0-16.6-9-16.6-14.9
			C284-87.3,284.2-87.4,284.4-87.6z"
          />
          <path
            class="st0"
            d="M167.9,29.7c-2.6,0.6-6.7,4.7-6.7,11.1c0,7.6,8.2,19.4,21.7,19.4c11.4,0,14.2-7.9,14.2-13.2
			c0-11.8-7.2-20.1-16.7-20.1c-5.1,0-9.8,3.7-9.8,9.3c0,5.2,3.9,12.9,13.2,12.9c4.4,0,6.9-3.1,6.9-6.7c0-3.5-3.2-8.3-7.9-8.3
			c-1.4,0-2.4,0.9-2.4,2.4c0,1.9,2.6,3.7,5.8,3.7c0.8,0,1.4-0.2,1.7-0.3c0.2,0.2,0.2,0.6,0.2,0.9c0,1.8-1.7,2.9-4.4,2.9
			c-4,0-7.8-3-7.8-7.7c0-2.8,2.4-4.8,5.9-4.8c6.9,0,11.1,6.2,11.1,12.3c0,5.5-3,8.7-9.3,8.7C171.2,52.3,165.2,41.1,167.9,29.7z"
          />
          <path
            class="st0"
            d="M250.9,59.8c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1s-1.4-3.1-3.1-3.1C252.3,56.7,250.9,58.1,250.9,59.8
			z M256.9,75.4c3.7,0,3.5-0.3,4.7-0.3c1.2,0,1.2,0.7,1.2,1.2c0,1.7-3.8,3.8-10.6,3.8c-4,0-6.4-2.1-7.1-4.5
			c-3.9,2.5-9.8,4.8-15.7,4.8c-16,0-20-6.9-25-9.2c-3.8-1.7-9.7,1.6-17.9-7.5c1.1,0.2,2.6,0.3,3.7,0.3c7.2,0,11-3.5,11-9.1
			c0-0.7-0.1-1.5-0.2-2.2c2.2,0.9,7.2,4.6,8.4,11.2c0.2,0.8,3.4,1.5,5.4,1.5c2.8,0,6.4-1.2,6.9-5.8c9.4-0.8,15.7-6.3,17-13.3
			c3.3,1.1,7.2,4.1,8.1,5.7c1.3-0.5,2.9-0.7,4.7-0.7c2.5,0,5.3,0.5,8.7,1.5c0.2-1,0.3-2.3,1.9-2.3c2.3,0,1.7,2.6,2.5,4.1
			c0.4,0.8,2.3,1.2,2.3,2.8c0,1.5-1.3,2-2,2c-0.3,0-0.7-0.1-0.9-0.2c-1.6,0.5-1.6,11.8-8.2,11.8c-4.8,0-9.3-4-11.2-9
			c-0.5-1.5-1.7-2.4-2.8-2.4c-1.2,0-1.9,0.2-3.4,1.9c-4.2,4.6-11.2,8.6-19.9,8.6c-2.7,0-4.8-0.2-5.3-0.2c-0.3,0-0.3,0.1-0.3,0.2
			c0,0.5,2.7,2.7,8.4,2.7c13.7,0,17.1-7.7,19.3-7.7c0.3,0,1.2,0,1.8,1.2C245.6,73.7,252.6,75.4,256.9,75.4z"
          />
          <path
            class="st0"
            d="M304.2,25.7c-1.6,0-10.7,12.1-16.5,12.1c-1.4,0-2.6-2.7-2.6-4.7c0-1.1,0.7-1.9,3.2-2.4
			c13.4-3,10.4-13.1,21.9-13.1c6.3,0,13.3,4.3,13.3,14c0,10.3-14.7,23.1-16.8,23.1c-1.8,0-3.7-2.6-3.7-4.3c0-0.5,0.2-1.2,1.8-1.9
			c7.5-3,11.8-9.8,11.8-13.3c0-0.8-0.4-1.6-1.4-1.6c-1.3,0-7,12.9-13.3,12.9c-4.4,0-8.8-4-8.8-5.8c0-3.1,12.6-8.9,12.6-13.3
			C305.6,25.8,304.9,25.7,304.2,25.7z M312.7,20.9c-1.1,0-2.3,0.9-2.3,3c0,2.3,1.8,3.8,2.8,4.3c0.5,0.3,1.4,0.6,2.3,0.6
			c2.3,0,4.1-0.6,4.1-2.5C319.5,23.8,316,20.9,312.7,20.9z"
          />
          <path
            class="st0"
            d="M166.9-69.5c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1
			C168.4-72.6,166.9-71.2,166.9-69.5z M162.9-76.5c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1
			C164.3-79.6,162.9-78.2,162.9-76.5z M160.1-55.1c-1.8,0.5-5.5,2.7-5.5,6.6c0,2.8,2.2,4.6,4.9,4.6c1.4,0,2.4-0.3,3-0.8
			c-2.2-2.1-3-4.4-3-6.8C159.5-53.2,159.8-54.2,160.1-55.1z M141-54c-0.1,0.4-0.1,0.8-0.1,1.2c0,3.7,1.9,5.7,4.5,5.7
			c1.9,0,4.2-1.2,6.2-3.7c-0.9,0.2-1.9,0.2-2.8,0.2C146-50.7,142.8-51.5,141-54z M138.2-47.1c0,5.9,1.8,8.7,5,8.7
			c2.5,0,5.8-1.8,9.8-5.2c-3,0.7-5.4,1.2-7.5,1.2C142.8-42.4,140.6-43.5,138.2-47.1z M158.4-78.3c0.4-1.2,0.7-2.8,0.7-4.5
			c0-3.7-1.7-9.4-7-9.4c-3,0-5.2,2.4-5.2,5.3C146.9-80.8,153.1-78.3,158.4-78.3z M151.8-58.6c-2.6,0-5.4-3.2-5.4-6.1
			c0-1.6,1.2-3.5,2-3.5c0.3,0,0.5,0.2,0.5,0.8c0.5,3.8,3,6,5.3,7.1c0.2,0.2,0.4,0.2,0.4,0.4C154.7-59.4,153.2-58.6,151.8-58.6z M159-68.2c0.5,0.7,2,1.6,2,2.2c0,1.1-0.6,2.5-1.9,2.5c-2.7,0-4.9-4.1-4.9-6.5c0-1.5,0.9-1.6,2-1.6c0.5,0,0.8,0.1,1.2,0.3
			C158.1-70.9,158.1-69.3,159-68.2z M140.7-58.5c-0.1,0.2-0.2,0.5-0.2,0.9c0,0.7,0.8,1.5,1.5,1.5c0.6,0,1-0.2,1.3-0.2
			c1.9,0,4,1.3,7,1.3c1.8,0,3.9-0.5,6.6-1.9c5.6-2.8,7.8-9.8,7.8-10.9c0-1.5-1.2-1.3-2.2-2.7c-1-1.4-0.6-2.6-1.2-3.2
			c-0.4-0.4-1.5-0.4-2.9-0.5c-0.5-0.1-1.1-0.1-1.7-0.1c-2.6,0-5.6,0.3-9,2.5C140.8-67.4,141.7-60.3,140.7-58.5z M166.6-61.6
			c-1.7,2-3.5,5.5-3.5,9c0,4.2,2.6,7.7,6.6,7.7c3.8,0,5.6-3.3,5.6-6.6C175.2-56.8,170.5-60.4,166.6-61.6z M149.9-76.6
			c0,0-2.3,1.7-5.1,1.7c-3.4,0-6-2.5-6-6c0-2.4,1.2-4.9,4.1-6.2c-0.1,0.5-0.1,0.9-0.1,1.3C142.8-81.3,145.3-77.9,149.9-76.6z"
          />
          <path
            class="st0"
            d="M287.4-50.7c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3
			C288.9-54,287.4-52.5,287.4-50.7z M287.6-40.9c0,2,1.6,3.7,3.7,3.7c2,0,3.7-1.6,3.7-3.7c0-2-1.6-3.7-3.7-3.7
			C289.3-44.5,287.6-42.9,287.6-40.9z M276.4-34.2c0,3,2.4,5.4,5.4,5.4c3,0,5.4-2.4,5.4-5.4c0-3-2.4-5.4-5.4-5.4
			C278.8-39.6,276.4-37.2,276.4-34.2z M279.3-45.2c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1
			C280.7-48.3,279.3-46.9,279.3-45.2z M269.1-39c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1
			C270.5-42.1,269.1-40.7,269.1-39z M277.1-57.2c0,2.7,2.3,5,5,5c2.7,0,5-2.3,5-5c0-2.7-2.3-5-5-5C279.4-62.2,277.1-60,277.1-57.2z M282.9-66.8c0,1.6,1.3,3,3,3c1.6,0,3-1.3,3-3c0-1.6-1.3-3-3-3C284.3-69.8,282.9-68.5,282.9-66.8z M268.8-59.3c0,1.6,1.3,3,2.9,3
			c1.6,0,3-1.3,3-3c0-1.6-1.3-3-3-3C270.2-62.3,268.8-61,268.8-59.3z M268.4-49.2c0,2.7,2.1,4.8,4.7,4.8c2.7,0,4.8-2.1,4.8-4.8
			c0-2.7-2.1-4.8-4.8-4.8C270.5-54,268.4-51.9,268.4-49.2z M258.6-44.2c0,2.4,2,4.4,4.4,4.4c2.4,0,4.4-2,4.4-4.4
			c0-2.4-2-4.4-4.4-4.4C260.6-48.7,258.6-46.6,258.6-44.2z M261.3-55.2c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1
			c0-1.7-1.4-3.1-3.1-3.1C262.7-58.3,261.3-56.9,261.3-55.2z M288.6-31.2c0-1.6,1.4-2.9,2.9-2.9c1.7,0,3,1.3,3,2.9c0,1.8-1.3,3-3,3
			C290.1-28.1,288.6-29.4,288.6-31.2z"
          />
          <path
            class="st0"
            d="M290.7,58.5c0.4,0,0.9,0.2,0.9,1.6c0,1.7-0.8,4-3.6,5.8c-2,1.3-4.6,2.4-8.5,2.5c0.6-1.9,1.1-3.8,1.1-5.7
			c0-0.6-0.1-1.1-0.2-1.8h1C287.6,61,290.2,58.5,290.7,58.5z M291.3,50.8c0.6-0.5,0.8-1.4,0.8-2.3v-1.8c0-2.3-3.4-3.6-8.1-3.6
			c-5.7,0-8.7,2.6-8.7,3.7v3.6c0,1.5,3.4,3,7.7,3C286.2,53.4,289.3,52.5,291.3,50.8z M295.3,51.7c-2,1.6-6.8,5.1-14.7,5.1
			c-4.2,0-6.1-1.3-6.7-1.3c-0.8,0-1.6,0.8-2.5,1.8c-1.9,2.2-4.3,6.2-4.3,10.7c0,3.1,2.4,6.2,6.9,6.2c14.6,0,25.5-9.1,25.5-16.5
			C299.6,53.4,297.1,51.7,295.3,51.7z"
          />
          <path
            class="st0"
            d="M298.1-13.9c0,1.9,0.6,4.2,2.6,4.2c1.6,0,3-2.5,3-5.2c0-3.1-0.5-5.5-0.5-6.8c0-0.5,0.2-0.8,0.5-0.8
			c1.2,0.1,2.3,3.3,3.5,3.3c1.4,0,3.5-2.6,4.4-2.6c0.2,0,0.3,0.3,0.3,0.4c0,1.2-1.8,3.8-1.8,4.8c0,0.9,4.5,1,4.5,1.8
			c0,1.2-4.7,1.2-7,2.2c-2.2,0.9-3.5,2.1-3.5,3.7c0,1.6,1.6,1.9,3.8,1.9c5.2,0,8.9-2.8,10-2.8c0.2,0,0.4,0.2,0.4,0.4
			c0,0.8-2.7,3.4-2.7,4.5c0,1.3,4.8,2.9,4.8,3.7c-0.1,0.2-0.4,0.3-0.9,0.3c-0.9,0-2.2-0.2-3.2-0.2c-0.6,0-1.2,0.1-1.4,0.4
			c-0.5,0.6,0.2,4.8-0.9,4.8c-1,0-3.7-9.2-9.7-9.2c-0.9,0-1.5,0.7-1.5,1.9c0,3.1,3,6.7,5.9,8.4c1.3,0.9,3.5,1.7,3.5,2.2
			c0,0.9-5,0.1-5,1.6c0,1.4,0.6,3.5,0.6,4.8c0,0.4-0.1,0.9-0.5,0.9c-0.9,0-3.2-4.3-4.3-4.3c-0.9,0-3,2.6-3.8,2.6
			c-0.2,0-0.4-0.2-0.4-0.5c0-1.8,2.9-5.9,2.9-9.8c0-1.9-0.2-5.9-2.5-5.9c-1.9,0-4,3-4.9,5.9c-0.5,1.7-0.2,8.3-1.4,8.3
			c-1.2,0-0.9-4.4-2.4-4.4c-1.2,0-3.6,2.1-4.5,2.1c-0.2,0-0.3-0.1-0.3-0.2c0-0.9,2.3-4,2.3-4.6c0-1.2-5.2-1.3-5.2-2.3
			c0-0.5,2-0.9,3.9-1.2c4-0.7,9.8-3.6,9.8-7.6c0-4.5-4.4-6.5-7.2-8.3c-1.5-0.9-3.1-1.7-3.1-2.3c0-0.6,1.9-0.6,2.7-0.6
			c1.2,0,2.3-0.1,2.3-1c0-0.7-0.2-2.1-0.2-3.2c0-0.8,0.1-1.4,0.4-1.4c1.2,0,2.8,3.8,3.6,3.8c1.2,0,3-3.1,4.1-3.1
			c0.4,0,0.5,0.2,0.5,0.9C299.6-20,298.1-16.8,298.1-13.9z M260.9-36.8c0.9,6.6,9.8,3.3,9.8,11.3c0,1.2-0.3,2.9-0.3,4.4
			c0,1.7,0.2,3.2,1.5,3.2c1.3,0,1.6-0.9,1.6-2.2c0-1.2-0.3-2.8-0.3-3.7c0-2.7,2-3.4,3.1-3.4c3,0,5.2,3,9.7,3c-1,5-11.3,3.7-11.3,9.1
			c0,2.7,2.7,3.4,4.5,3.4c1.6,0,2.2-0.2,2.2-1.6c0-0.8-0.2-1.6-0.2-2.2c0-0.3,0.2-0.6,0.6-0.6c1.7,0,10.1,6.4,10.1,8.4
			c0,1.8-8.9,3.6-11.5,3.6c-0.8,0-0.9-0.5-0.9-0.9c0-0.5,1.3-2.2,1.3-3.1c0-0.7-1.2-1.2-4.3-1.2c-1.5,0-2.6,0.2-2.6,1.3
			c0,2.1,3.4,4.7,3.4,6.6c0,1.6-0.9,2.5-3.4,2.5c-2.1,0-5.3-1.6-5.3-4.4c0-2.2,2.2-4.4,2.2-5.7c0-0.4-0.3-1-1.2-1
			c-2.9,0-3.7,7.6-13.9,8.5c1.6-1.5,3-3.1,3-6.5c0-1.6-0.4-3-0.4-4.8c0-2.3,1.1-3.5,2.7-3.5c2.9,0,4.3,4.1,6.2,4.1
			c1,0,2.1-0.9,2.1-2.3c0-5.9-11.4-5.1-11.4-13.4C258-30.1,258.8-32.9,260.9-36.8z"
          />
          <path
            class="st0"
            d="M275.7,13.9c0,1.1,0.8,1.9,1.9,1.9c1.2,0,2-1.1,2-2.3c0-1.6-1.5-3.9-2.9-3.9c-0.6,0-0.4,0.9-0.5,2
			C276.1,12.3,275.7,13.3,275.7,13.9z M271.6,3.5c8.2,1.6,12.6,7.7,12.6,13.5c0,4-2.5,7.6-6.9,7.6c-4,0-6.9-2.7-6.9-7.1
			c0-3.3,2.6-6.7,2.6-10.1C273.1,6.2,272.7,4.9,271.6,3.5z M261.3,16.8c0.8,0.8,1,1.6,1,2.3c0,1.2-0.5,2.4-0.5,3.7
			c0,1.9,1.4,3.5,3.4,3.5c2.2,0,3.7-1.7,3.7-3.7C268.8,19.7,265.8,16.8,261.3,16.8z M287.1,11.8c0.5,0.9,0.8,1.7,0.8,2.5
			c0,2.2-1.5,4.2-1.5,6.5c0,2.6,2,4.7,4.9,4.7c3.2,0,5.4-2.6,5.4-5.6C296.7,15.7,292.9,12.2,287.1,11.8z M263.1,7.7
			c3.6,0.5,4.5,1.7,4.5,2.7c0,1.7-1.6,2.3-3.1,2.3c-1.1,0-2.3-0.2-3.7-0.2c-1.7,0-3.4,0.4-4.8,2c1,0.2,2.5,0.9,2.5,2.4
			c0,4.5-12.6,0.8-12.6,12.4c0,6.2,6.4,12.9,16.1,12.9c5,0,8.2-1.6,8.2-3.5c0-1.5-2.7-0.5-2.7-1.7c0-0.5,0.9-1.6,0.9-2.4
			c0-0.4-0.3-1.1-0.9-1.1c-1.4,0-4.1,2.3-9.4,2.3c-4.2,0-8.3-2.4-8.3-5.7c0-0.7,0.3-1.1,0.8-1.1c0.5,0,1.1,0.3,1.6,0.3
			c0.5,0,0.5-0.3,0.5-1.2c0-0.9,0-3.3,1.5-3.3c1.5,0,4.3,5.5,15.1,5.5c3.6,0,10.5-0.7,13.1-3c-0.1,8.6-6.8,19.9-20.8,19.9
			c-14.6,0-22.4-11.7-22.4-22.5c0-11.9,9.7-22.8,23.1-22.8c3.4,0,6.3,1.2,6.3,2.6C268.6,6.5,263.5,5.2,263.1,7.7z"
          />
          <path
            class="st0"
            d="M212.7-81.4c5.1,0,7.5,4.4,7.5,9.4c0,2.2-0.6,5.4-1.6,5.4c-1,0-0.9-1.8-1-3.4c-0.2-2.1-0.5-2.6-1.2-2.6
			c-1.3,0-2.2,3.1-2.2,6c0,1.8,0.3,3.8,1.7,3.8c0.8,0,1.5-0.4,2-0.9c-0.3,3.6-3,6.7-6.7,6.7c-6.9,0-9.4-8-10.2-12.7
			c-4.1,4.4-8,7.6-14.8,7.6c-6.4,0-9.3-4.7-9.3-9.1c0-1.3,0.5-4.8,2.1-4.8c1.9,0,0.2,6.4,4.1,6.4c0.9,0,2.7-0.8,3.6-3.4
			c0.5-1.6,0.9-3.2,0.9-4.6c0-2.1-0.5-3.4-2-3.4c-1.3,0-3,1-3.5,1.6c1.9-5.6,6.4-8.5,11.4-8.5c6.2,0,9.4,5.5,9.4,11.5
			c0,0.9-0.1,1.8-0.2,2.7C205-78.1,208.7-81.4,212.7-81.4z M206.7-72.2c0-1.3-0.3-1.6-0.6-1.6c-0.8,0-1.6,0.9-2.9,3
			c0.2,2.8,0.6,4.1,1.4,4.1C205.4-66.8,206.7-69.5,206.7-72.2z M196-69c0.9,0,2-0.9,4-3.2c0.2-1,0.2-2,0.2-3.1
			c0-1.6-0.6-3.3-1.6-3.3c-2.3,0-3.5,6.2-3.5,7.9C195-69.8,195.5-69,196-69z M208.7-62.1c1.9,0,4.1-7.5,4.1-12.2
			c0-1-0.2-2.1-0.6-2.1c-1.6,0-4.4,7.7-4.4,11.9C207.8-62.8,208.2-62.1,208.7-62.1z M189.6-66.4c2.7,0,5.7-14.4,5.7-16.8
			c0-0.6-0.2-1.1-0.8-1.1c-2.4,0-5.8,13.7-5.8,16.7C188.7-67,188.9-66.4,189.6-66.4z"
          />
          <path
            class="st0"
            d="M198.1-60.4c-3.4,0-3.5,4.1-3.5,12.2c-2.5-5.4-6.6-11.2-11.5-11.2c-3.3,0-4.5,2.2-4.5,6c0,0.8,0.1,2,0.2,2.7
			c0.4-1.3,1.6-1.9,3.2-1.9c3.4,0,7.4,2.6,10.1,6.1c-3-0.5-6-1.1-8.5-1.1c-1.6,0-3,0.2-4.1,0.7c-2.3,1-5.3,4.4-5.3,9.4
			c0,0.8,0.1,1.6,0.2,2.6c2.7-6.1,10-8.8,14.7-8.8c0.9,0,1.6,0.1,2.2,0.2c-2.4,2.2-3,6-3.1,8.6c-0.1,1.2-0.5,1.3-1.4,1.3
			c0,2.6,2,6.4,2,8.3c0,0.7-0.4,1.3-0.8,1.5c0,3.4,3.4,5.9,3.4,8.1c0,0.5-0.2,1-0.4,1.2c0.2,1.4,4,3.8,4.5,4.9
			c0.6,1.4,1.3,1.7,1.7,1.7c1.3,0,3.4-1.6,3.4-3c0-0.5-0.4-1-0.7-1.2c-1.5-1.1-2.3-3.4-2.9-5c-0.5,0-1.3-0.2-1.5-1.1
			c-0.4-1.6-0.4-4.6-1.1-6.4c-1-0.2-1.5-0.6-1.5-2.7c0-1,0.6-5,0.6-6.4c-0.5,0-1.1-0.3-1.1-1.2c0-2.5,2.5-4.4,3.2-7.6
			c4.4,3.3,5.5,6.6,5.5,9.6c0,1.6-0.2,3-0.5,4.4c3.3-1.6,8.2-4.1,8.2-9c0-2.9-1.3-6.1-10.1-7.3c2.7-1,6.2-1.6,9-1.6
			c5,0,7.9,1.6,8.5,5.1c1-1.6,1.8-3.4,1.8-5c0-3.8-3.2-6.3-7.3-6.3c-4.8,0-9,2.5-13.5,5.5c2-4.2,3.4-6.7,5.9-6.7
			c0.4,0,0.8,0.1,1.2,0.2C203.5-57.1,199.9-60.4,198.1-60.4z"
          />
          <path
            class="st0"
            d="M329.5-4.2c3.9,0,7,5.1,7,11c0,6.3-3,13.2-8.3,17.9c-0.1-10.5-13-7.2-13-14.8c0-3.3,2.3-5.5,5.2-5.5
			c2.5,0,4.4,0.7,5.9,2.1c-1.4-1.3-2.1-3.2-2.1-5C324.2-1.5,326.1-4.2,329.5-4.2z"
          />
          <path
            class="st0"
            d="M271.6-84c-0.6-1-3-1.6-4-1.6c-3.4,0-5.8,2.4-6.2,4.6c-0.3-0.8-0.5-1.7-0.5-2.7c0-3.4,2.3-7.6,8-7.6
			c5,0,6.9,2.6,7.6,2.6c0.8,0,1.6-0.8,2.2-0.8c0.9,0,0.9,1.3,0.9,2.3c0,3.1-1.4,8.5-4.9,10.5c-0.6-1.3-1.9-2.8-3.9-3.7
			c-0.5-0.2-0.8-0.7-0.8-1.2C269.9-82.4,270.7-83.5,271.6-84z M259.9-78.3c2,2,3.8,2.8,6,2.8c0.9,0,1.9-0.2,2.4-0.2
			c0.7,0,1.2,0.3,1.2,0.9c0,0.9-0.8,2.3-1.7,3c0.9,0.4,2,0.5,3,0.5c4.4,0,9.5-2.8,9.5-9.8c0.9,1.2,1.5,3.8,1.5,5
			c0,8.5-6.2,11.8-12,11.8c-3.8,0-5.6-1.2-6.1-1.2c-1.5,0-1.4,3-2.4,2.9c-1.8-0.3-3.4-4.3-3.4-8.5C257.8-73.7,258.5-76.3,259.9-78.3
			z"
          />
          <path
            class="st0"
            d="M193.1-127.9c0-0.5-2.3-0.9-5.5-0.9c-5.1,0-4.4,4.2-8.3,4.2c-4.1,0-3-4.4-8.2-4.4c-3.1,0-6,0.5-6,1.2
			c0,0.9,3.7,0.5,3.7,3.1c0,2.7-7.9,3.5-7.9,5.1c0,0.5,0.6,0.8,1.4,0.8c1.7,0,4.4-0.8,6.4-0.8c1.1,0,2.1,0.2,2.1,1.2
			c0,2-4.2,5.5-4.2,7c0,0.5,0.4,0.6,0.8,0.6c1.6,0,4.2-4,6.7-4c4.1,0,1.4,8.8,3.7,8.8c2.3,0,1.3-8.7,4.7-8.7c2.7,0,4.8,6,6.6,6
			c0.5,0,0.9-0.4,0.9-0.9c0-1.6-2.3-5-2.3-7.2c0-1.3,0.9-2,2.2-2c1.8,0,4.2,1.1,5.5,1.1c0.5,0,0.9-0.2,0.9-0.7
			c0-1.9-6.9-3.9-6.9-6.3C189.3-126.8,193.1-126.8,193.1-127.9z M211.4-120c0,1.4,4.9,4.4,7.2,4.4c1.3,0,1.7-1.8,1.7-3.7
			c0-2.3-1-2.4-3-2.4C214.7-121.6,211.4-120.9,211.4-120z M219.5-104.5c0-4.7-12.7-13.2-12.8-16.8c0-0.3,0.2-0.7,0.5-1
			c2.2-2.3,11.2-4,11.2-5.9c0-1-1.9-1-2.8-1c-7.3,0-17.9,2.7-17.9,5.2c0,2.7,9.5,9.1,9.5,12.3c0,0.9-0.5,1.2-1.4,1.2
			c-2.6,0-8.4-3.2-11.2-3.2c-1.2,0-1.7,0.9-1.7,2.1c0,3.7,4.4,10.8,4.4,12.9c0,0.2-0.1,0.5-0.4,0.5c-1.4,0-8-7.8-10.4-7.8
			c-0.9,0-0.9,1.2-0.9,2.9v2.7c0,1.8-0.1,2.9-0.9,2.9c-2,0-3-5.7-4.9-5.7c-2,0-2.4,10.1-4.4,10.1c-2.3,0-0.3-11.2-2.5-11.2
			c-2.4,0-5.1,7.4-6.9,7.4c-0.6,0-0.8-0.6-0.8-1.2c0-2.3,1.3-5.9,1.3-8.1c0-1.1-0.3-1.7-1.2-1.7c-2.4,0-7.7,3.9-9.8,3.9
			c-0.3,0-0.7-0.2-0.7-0.5c0-1.9,8.5-7.8,8.5-10.5c0-2.6-11.7-0.9-11.7-3.8c0-2.5,8.4-5.1,8.4-7.6c0-2.3-9.9-3.3-13.7-3.3
			c-4.6,0-7.1,0.5-7.1,1.9c0,2,10.5,2.7,10.5,4.4c0,1.7-10.8,6.3-10.8,9c0,2.3,15.3,0.2,15.3,2.6c0,2.7-13.3,14.1-13.3,17.9
			c0,0.9,0.6,1.2,1.5,1.2c4.1,0,14.6-7.7,17.5-7.7c1,0,1.1,1.2,1.1,2.3c0,2-0.7,5.1-0.7,7.4c0,1.8,0.5,3,2,3c2.9,0,5.5-4.9,7.5-4.9
			c2.5,0,1,14.4,4.8,14.4c3.2,0,4.5-15.6,6.5-15.6c2,0,3.7,4.5,5.5,4.5c2.3,0,1.2-7.9,3.7-7.9c2.7,0,10.5,9.4,14,9.4
			c1.1,0,1.5-0.9,1.5-2c0-4.1-5.8-13.5-5.8-16.5c0-0.8,0.2-1,1.1-1c3,0,11.9,4.2,16.1,4.2C218.7-102.7,219.5-103.5,219.5-104.5z M148.3-109c0,1.6-5.9,6.8-8,6.8c-0.8,0-1-0.9-1-1.8c0-1.6,0.7-3.7,1.6-4.4c1-0.9,3.4-1.4,5.1-1.4
			C147.1-109.9,148.3-109.7,148.3-109z M205.6-101c0,2.7,9.8,16.1,11.6,16.1c1.6,0,2.7-2.7,2.7-5.1c0-0.6-0.2-1.2-0.5-1.7
			c-1.4-2-11.3-9.8-13.5-9.8C205.8-101.5,205.6-101.2,205.6-101z"
          />
          <path
            class="st0"
            d="M330.9-124.6c0,1.1,0.9,1.9,2,1.9c1.1,0,2-0.9,2-1.9c0-1.1-0.9-2-2-2C331.8-126.6,330.9-125.7,330.9-124.6z M318.2-107.4c0-5.5-13.3-6.9-13.3-8.2c0-1,7.4-2.7,11.2-2.8c-5.9-7.5-14.2-6.7-14.2-8c0-0.8,6.2-2.3,12-2.3
			c4.8,0,9.4,1.1,9.4,4.8c0,2-1.6,4.1-1.6,7.3c0,1.7,0.9,3.2,3.1,3.2c2.4,0,3-2.6,3-5.3c0-2.3-0.4-4-0.4-5.1c0-3.3,2.2-5.5,5.4-5.5
			c2.7,0,3.3,1.2,7.2,1.2c-2.1,1.3-2.9,3.5-2.9,4.6c0,3.3,2.1,5.5,2.1,12.2c0,12.3-11.5,16.7-25.7,16.7c-3.9,0-8-0.3-12.1-0.9
			c-0.6-0.1-0.8-0.2-0.8-0.6c0-1,5.5-1.6,5.5-6.6c0-2-0.6-3.8-0.6-4.5c0-0.4,0.3-0.6,0.6-0.6c1.2,0,3.5,4.5,7.9,4.5
			C315.3-103.4,318.2-103.8,318.2-107.4z M331.4-115.3c0,6.9-5.1,12.2-14.2,13.7c1,0.1,2,0.1,2.9,0.1c10.7,0,14.6-4.8,14.6-8.9
			C334.7-112.6,333.4-114.6,331.4-115.3z"
          />
          <path
            class="st0"
            d="M326-14.7c1.2-3.9,2.8-6.6,5.4-10.8c-2.6-1.3-9.4-4.1-9.4-8.7c0-5.4,6.7-9.6,8.1-9.6c0.6,0,0.8,0.5,0.8,1.6
			c0,2.8-1.3,7.3-1.3,9.8c0,2.2,1.4,4.3,2.7,4.3c1.3,0,5-4.7,5-9.3c0-8.3-7.3-10.8-9.5-10.8c-2.1,0-5.9,3.4-14.9,3.4
			c-16.8,0-20.6-16.6-20.6-23.9c0-0.3,0.1-0.4,0.2-0.4c0.4,0,2,2,7.3,2c2.7,0,5.2-0.7,9.2-0.7c9.3,0,13.5,6.5,19.3,14.7
			c3.5,2.2,11.9,5.7,11.9,15.6c0,8.7-9.3,13.6-11.5,22.9c3.6-0.4,5.7-6.8,9.4-6.8c1.9,0,2.7,1.5,2.7,3.8c0,3.4-2,8.6-5.7,11.8
			c0.1-0.3,0.1-0.7,0.1-1.2c0-2.2-0.9-5.3-3.4-5.3c-3.9,0-5.1,4.2-7.3,7.7c-2.3-8-5.3-15.1-10.8-19.5c-5.5-4.4-15.1-2-15.1-10.1
			c0-2.2,1.4-5.3,1.4-7.8c0-0.9-0.2-1.6-0.6-2.3c7.5,2.8,4.8,7.8,9.5,12.9c2.9,3.3,10.8,6.4,15.4,19.9c0.1-0.4,0.2-1.4,0.2-2.1
			c0-11.5-15.3-22.9-16.6-27.5c8.9,1,10.4,3.2,14.7,14.9C323.2-23.7,326-17.7,326-14.7z M324-52.6c0-1.6-4.1-1.8-4.5-6.4
			c-0.1-0.7-0.3-1.9-1.2-1.9c-1.4,0-0.3,4.3-1.9,4.3c-3.6,0-4.8-7-6.1-7c-0.3,0-0.7,0.2-0.7,1.2c0,0.9,0.2,1.4,0.2,2.4
			c0,1-0.2,1.7-1.2,1.7c-2.1,0-4-4.9-5.8-4.9c-0.4,0-0.8,0.2-0.8,0.6c0,0.9,1.6,2.7,1.6,3.5c0,1.9-5.1,0.9-5.1,2.4
			c0,0.7,0.7,0.9,1.6,0.9c1.6,0,4.2-0.7,5.5-0.7c0.8,0,2,0.2,2,1.2c0,1.1-2.8,2.5-2.8,3.5c0,0.5,0.5,0.7,0.9,0.7
			c2.1,0,5.8-3.7,8.5-3.7c0.5,0,1.6,0.2,1.6,0.9c0,1-2.6,2.8-2.6,4.3c0,0.4,0.2,0.9,0.7,0.9c1.4,0,3.3-3.7,5.9-3.7
			c1,0,2.5,0.4,3.3,0.4C323.7-51.9,324-52.3,324-52.6z"
          />
          <path
            class="st0"
            d="M265.9-99.3c1.3,0,1.5,3,1.7,4.8c0.2,0.9,0.3,1,0.7,1c0.9,0,2.4-3.6,3.4-3.6c1.5,0,5.5,4.1,12.1,4.1
			c9.4,0,18.3-6.6,18.3-18.3c0-11.3-8-17.9-19-17.9c-5.2,0-9.1,2.6-11.1,2.6c-1.2,0.1-3.1-3-4-3c-0.4,0-0.8,0.8-0.8,2.1
			c0,0.9,0,3.6-0.9,3.6c-1.9,0-9.1-5.4-9.1-3.4c0,1.8,4.8,7.3,4.8,8.7c0,1.4-4.8,2.7-4.8,3.7c0,1.7,5.1,2,5.1,4.2
			c0,2.2-5.3,3.9-5.3,5.5c0,1.3,5.3,0.4,5.3,2c0,1.6-3.9,7.1-3.9,8.7c0,0.4,0.1,0.4,0.4,0.4C260.3-94.2,264.8-99.3,265.9-99.3z M268.1-112.1c0-0.9-3-2.5-3-3.4c0-0.2,0.2-0.2,0.4-0.2c0.9,0,2.3,0.3,3.5,0.3c1,0,1.2-4.4,1.9-4.4c0.5,0,1.5,4.1,2.3,4.1
			c1,0,2.9-1.2,3.7-1.2c0.2,0,0.3,0.1,0.3,0.2c0,0.5-2,2.6-2,3.3c0,0.7,4.4,1.6,4.4,2.2c0,0.8-4.5,0.7-4.5,1.4
			c0,0.5,1.6,2.7,1.6,3.4c0,0.2-0.1,0.2-0.2,0.2c-0.6,0-3-1.1-3.7-1.1c-0.9,0-2.2,3.2-3,3.2c-0.2,0-0.4-0.3-0.4-0.7l-0.2-3.1
			c0-0.3-0.2-0.6-0.5-0.6c-0.9,0-2.7-0.1-3.3-0.1c-0.4,0-0.5-0.1-0.5-0.2C265-109.3,268.1-111.5,268.1-112.1z M283.3-97.3
			c-5.5,0-9.1-2.7-9.1-4.1c0-0.7,0.5-1.2,1.1-1.2c1.7,0,5.5,2.6,6.7,2.6c0.3,0,0.5-0.2,0.5-0.6c0-1.4-1.3-3.7-1.3-4.9
			c0-0.3,0.2-0.5,0.7-0.5c2.1,0,5.2,0.9,6.9,0.9c0.5,0,0.7-0.3,0.7-0.6c0-1.7-5.1-5.1-5.1-6.1c0-0.9,6.3-2.3,6.3-3.8
			c0-1.6-8.6-0.5-8.6-2c0-1.1,1.6-3,1.6-3.8c0-0.4-0.3-0.5-0.9-0.5c-0.7,0-4.5,1.5-6,1.5c-0.8,0-1.7-0.2-1.7-1.2
			c0-1.8,3-3.7,7.8-3.7c7.7,0,12.3,5.3,12.3,14.2C295.4-100.1,287.6-97.3,283.3-97.3z"
          />
          <path
            class="st0"
            d="M230.5,37.7c0,0.7-0.2,1.5-0.9,2.3c-0.9-1.6-3.3-2.3-4.4-2.3c-8,0-5,11.6-13.6,11.6c-3.6,0-3.9-0.5-5.2-0.5
			c-0.5,0-0.9,0.2-0.9,0.7c0,2,2.9,7,10.9,7c8,0,15.8-4.9,15.8-11c0-1-0.2-2.1-0.7-3.2c0.3-0.5,1.1-0.8,1.8-0.8
			c1.2,0,1.9,1.4,3.1,1.4c1.2,0,2.2-0.9,2.2-2.2c0-1-0.5-1.7-1.5-2.2c0.6-0.5,1.1-1.2,1.1-2.1c0-1.3-0.9-2.6-2.5-2.6
			c-0.7,0-1.2,0.2-1.7,0.8c-0.2-1.4-0.9-2.7-2.4-2.7c-1.2,0-2,0.9-2,2.2C229.7,35.4,230.5,36.4,230.5,37.7z"
          />
          <path
            class="st0"
            d="M209.5,40.7c-2.3,0-5.9-2.5-5.9-3.9c0-1.2,1.1-1.8,1.9-1.8c2,0,5.5,2.4,5.5,4.4
			C211.1,40,210.7,40.7,209.5,40.7z M187.8,23.2c6.2,1.6,9.4,4.9,10,8.9c0.6,3.8,1.4,6.9,2.9,8.5c2,2.2,5.8,4.1,9.1,4.1
			c2.8,0,5.1-0.9,5.1-3.5c0-2.8-3.8-9.1-9.4-9.1c-0.5,0-1.2,0.1-2,0.1c-0.7,0-1.7-0.2-2.3-0.7c-1.2-1.1-1.6-6.9-4-10.5
			c-2.6-3.9-5.4-5-9.2-5c-2.7,0-5.8,1.5-5.8,3.7C182.1,21,182.8,21.9,187.8,23.2z"
          />
          <path
            class="st0"
            d="M226.9,13.7c0-3.5-7.3-3.6-7.3-8.9c0-1.3,0.9-3.6,2.6-6c0.7,4.1,2.3,6.2,5.9,7.5c6.9,2.6,7.9,6.5,7.9,10.6
			c0,7-4.9,15.8-17.6,15.8c-10.1,0-17.4-9-17.4-16.7c0-2.1,0.5-4.4,1.9-4.4c1.2,0,1.6,0.9,2.5,1.9c3.5,3.5,7.3,4.4,12.9,4.4
			C223.3,17.8,226.9,15.8,226.9,13.7z M227.2,19.3c-2.7,1.2-3.7,1-3.7,3.7c0,1-0.2,2.3-0.2,3c0,0.9,0.2,1.5,1.2,1.5
			c2.8,0,7.3-4.8,7.3-8.8c0-1.1-0.6-1.6-1.3-1.6C229.8,17.2,229.1,18.4,227.2,19.3z M202.4-20.1c0-5,11.9-7.6,11.9-13.8
			c0-1-0.4-2.8-1.1-4.1c4.1,2.3,7.2,6.1,7.2,9.2c0,5.4-5.6,7.9-5.6,11.2c0,3.9,6,4.5,6,9.4c0,4-5.7,8.6-5.7,13.7
			c0,4,2.8,7.3,7.1,7.8c-1.4,0.3-2.7,0.5-4,0.5c-7.9,0-16-6.5-16-14.2c0-3.8,1.6-7.2,4.1-9.6c2.2,1.9,4.4,3.1,5.8,3.1
			c1.2,0,1.8-0.6,1.8-1.6C213.8-11.3,202.4-13.9,202.4-20.1z"
          />
          <path
            class="st0"
            d="M157.1,18.4c0.3,2.7,2.8,4.7,5.6,4.4c1-0.1,2-0.4,2.6-0.8c0.6,2.5,2.7,4.1,5.5,3.7c2.9-0.4,4.8-2.6,4.5-5.5
			c-0.1-0.8-0.2-1.2-0.5-1.8c0,0,0.4,0,0.7-0.1c2.8-0.5,4.7-2.8,4.5-5.5c-0.3-2.8-2.8-4.9-5.5-4.6c-1,0.1-2,0.5-2.6,1.1
			c-0.7-2.3-2.9-4.4-5.9-3.9c-2.7,0.4-4.5,2.8-4.2,5.7c0,0.5,0.2,1,0.4,1.6c0,0-0.5,0.1-0.8,0.1C159.2,13.1,156.7,15.4,157.1,18.4z M171.7,15.4c0.2,1.6-1,3.1-2.6,3.3c-1.6,0.2-3.1-1-3.3-2.6c-0.2-1.6,1-3.1,2.6-3.3C170,12.6,171.5,13.7,171.7,15.4z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.st0 {
  fill: #fff;
}
svg {
  height: 3.4rem;
  padding: 5px;
}
*::-ms-backdrop, svg {
  width: 35px;
}
</style>
